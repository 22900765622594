import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { UserProvider } from './hooks/useAuth';

import App from './App';

import './styles/main.scss';

import 'react-toastify/dist/ReactToastify.css';

const AppProvider = ({ children }) => <UserProvider>{children}</UserProvider>;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AppProvider>
      <App />
      <ToastContainer />
    </AppProvider>
  </BrowserRouter>,
);
