import api from './api';

const getAllGames = () => {
  const response = api.get('wp/v2/games');

  return response;
};

const getGameById = (id) => {
  const response = api.get(`wp/v2/games/${id}`);

  return response;
};

const gameServices = {
  getAllGames,
  getGameById,
};

export default gameServices;
