import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';

import { Layout, Spinner } from '../components';

import { Tasks } from '../sections';

import userServices from '../services/user.services';
import gameServices from '../services/games.services';

const SingleGame = () => {
  const [game, setGame] = useState();
  const [progress, setProgress] = useState();
  const [isPlayer, setPlayer] = useState(false);
  const [confetti, setConfetti] = useState(false);

  const { id } = useParams();
  const [cookies] = useCookies();

  const getGameData = async () => {
    try {
      const response = await gameServices.getGameById(id);

      if (response.status === 200) {
        const gameInfo = {
          info: response.data.acf.game_info,
          ...(response.data.acf.status.is_running && {
            levels: [
              ...response.data.acf.levels.map((level) => {
                delete level.level_code;

                return level;
              }),
            ],
            is_running: response.data.acf.status.is_running,
          }),
        };

        if (response.data.acf.players) setPlayer(response.data.acf.players.includes(cookies.id));

        setGame(gameInfo);
      }
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please reload the page!');
      console.log(error);
    }
  };

  const getUsersGameProgress = async () => {
    try {
      const response = await userServices.getProfile(cookies.id);

      if (response.status === 200) {
        const user_progress = response.data[0].acf.games.filter((el) => Number(el.game_id) === Number(id))[0].progress;

        setProgress(user_progress);
      }
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please reload the page!');
      console.log(error);
    }
  };

  const checkAnswer = async (event, level) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      const response = await gameServices.getGameById(id);

      if (response.status === 200) {
        if (response.data.acf.levels.find((el) => el.level_name == level).level_code === data.get('answer')) {
          toast.success(`Correct answer!`, {
            theme: 'dark',
            // position: 'bottom-left',
            autoClose: 3000,
          });
          nextLevel(level);
          setConfetti(true);
          setTimeout(() => setConfetti(false), 7000);
        } else {
          toast.error(`Incorrect answer!`);
        }
      }
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please try again!');
      console.log(error);
    }
  };

  const nextLevel = async (level) => {
    const level_index = progress.findIndex((obj) => obj.level_name === level);

    progress[level_index].status = true;

    try {
      const user = await userServices.getProfile(cookies.id);

      if (user.status === 200) {
        const update = {
          user_info: {
            ...user.data[0].acf.user_info,
          },
          games: [
            ...user.data[0].acf.games.filter((el) => Number(el.game_id) !== Number(id)),
            {
              game_id: Number(id),
              progress,
            },
          ],
        };

        const response = await userServices.updateProfile(
          cookies.profile_id,
          { status: 'publish', fields: update },
          cookies.token,
        );

        if (response.status === 200) {
          getUsersGameProgress();
        }
      }
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please try again!');
      console.log(error);
    }
  };

  useEffect(() => {
    getGameData();
  }, []);

  useEffect(() => {
    isPlayer && getUsersGameProgress();
  }, [isPlayer]);

  if (!game) return <Spinner />;

  return (
    <Layout cookies={cookies}>
      {game.is_running && progress && (
        <Tasks userProgress={progress} tasks={game.levels} submit={checkAnswer} confetti={confetti} />
      )}
      {/* <Description /> */}
    </Layout>
  );
};

export default SingleGame;
