import { isMobile } from 'react-device-detect';
import { useCookies } from 'react-cookie';

import { useAuth } from '../hooks/useAuth';

import { Layout, LoginForm } from '../components';

import { MobileOnly } from '../sections';

const Login = () => {
  const { login } = useAuth();
  const [cookies] = useCookies();

  const submit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    login({ username: data.get('username'), password: data.get('password') });
  };

  if (!isMobile) return <MobileOnly />;

  return (
    <Layout cookies={cookies}>
      <LoginForm submit={submit} />
    </Layout>
  );
};

export default Login;
