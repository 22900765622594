import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import userServices from '../services/user.services';

import { Layout, Spinner, UserInfoForm } from '../components';

const base_user_info = {
  personal: {
    phone_number: '',
    birthday: '',
    facebook_link: '',
  },
  car: {
    make: '',
    model: '',
    plate: '',
  },
};

const Profile = () => {
  const [userData, setUserData] = useState();
  const [cookies] = useCookies();
  const navigate = useNavigate();

  const getProfileData = async () => {
    try {
      const response = await userServices.getProfile(cookies.id);

      if (response.status === 200) {
        const user_info = response.data[0].acf.user_info ? response.data[0].acf.user_info : base_user_info;

        setUserData(user_info);
      }
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please reload the page!');
      console.log(error);
    }
  };

  const submit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (data.get('phone').length !== 15) return toast.error('Invalid phone format');

    if (data.get('bd').length !== 10) return toast.error('Invalid birthday format');
    else if (data.get('bd').split('/')[0] > 12) return toast.error('Invalid month format');
    else if (data.get('bd').split('/')[1] > 31) return toast.error('A month cannot have more than 31 days');
    else if (data.get('bd').split('/')[0] === '02' && data.get('bd').split('/')[1] > 29)
      return toast.error('February cannot have more than 29 days');
    else if (new Date().getFullYear() - Number(data.get('bd').split('/')[2]) < 16)
      return toast.error('To be a driver you must be at least 16 years old');

    const user_info = {
      personal: {
        phone_number: data.get('phone'),
        birthday: data.get('bd'),
        facebook_link: data.get('fb'),
      },
      car: {
        make: data.get('car_make'),
        model: data.get('car_model'),
        plate: data.get('car_plate'),
      },
    };

    const profile_card = {
      status: 'publish',
      fields: {
        user_info,
      },
    };

    try {
      const response = await userServices.updateProfile(cookies.profile_id, profile_card, cookies.token);

      if (response.status === 200) {
        navigate('/');
        toast.success('Updated');
      }
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please try again!');
      console.log(error);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  if (!userData) return;

  return (
    <Layout cookies={cookies}>
      <UserInfoForm submit={submit} data={userData} />
    </Layout>
  );
};

export default Profile;
