import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';

import userServices from '../services/user.services';

import { Layout, SingUpForm } from '../components';

const SignUp = () => {
  const navigate = useNavigate();
  const [cookies, setCookies] = useCookies();

  const submit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (data.get('email') === 'yasya8909@gmail.com')
      return toast.warning('ИДИ НАХУЙ, ШКУРА!', {
        theme: 'light',
        autoClose: false,
        position: 'bottom-left',
        hideProgressBar: true,
      });

    if (data.get('password') !== data.get('password_confirm')) return toast.error('Password mismatch');

    const user = {
      username: data.get('username'),
      password: data.get('password'),
      email: data.get('email'),
      first_name: data.get('firstName'),
      last_name: data.get('lastName'),
    };

    try {
      const response = await userServices.register(user);

      if (response.status === 201) {
        setCookies('username', user.username);
        toast.success('Registration Completed Successfully');
        navigate('/sign-in');
      }
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please try again!');
      console.log(error);
    }
  };

  return (
    <Layout cookies={cookies}>
      <SingUpForm submit={submit} />
    </Layout>
  );
};

export default SignUp;
