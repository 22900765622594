import { Fragment } from 'react';

import { Header, Footer } from './';

const Layout = ({ cookies, children }) => (
  <Fragment>
    {cookies.token && <Header />}
    <main>{children}</main>
    {cookies.token && <Footer />}
  </Fragment>
);

export default Layout;
