import { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBurger,
  faCircleXmark,
  faRightFromBracket,
  faUser,
  faStar,
  faTrophy,
} from '@fortawesome/free-solid-svg-icons';

import { useAuth } from '../../hooks/useAuth';

import { Container } from '../';

import styles from './header.module.scss';

const Header = () => {
  const { logout } = useAuth();
  const [isVisible, setVisible] = useState(false);

  const toggleMenu = () => setVisible(!isVisible);

  return (
    <Fragment>
      <header className={styles.header}>
        <Container>
          <div className={styles.header__inner}>
            <NavLink to="/" className={styles.logo}>
              WEST
              <br />
              QUEST
            </NavLink>

            <button className={styles.burger} onClick={toggleMenu}>
              <FontAwesomeIcon icon={faBurger} fontSize={36} />
            </button>
          </div>
        </Container>
      </header>

      <nav className={`${styles.navigation} ${!isVisible ? styles['navigation--hidden'] : ''}`}>
        <Container>
          <div className={styles['navigation__inner']}>
            <button className={styles.close} onClick={toggleMenu}>
              <FontAwesomeIcon icon={faCircleXmark} fontSize={36} />
            </button>
            <NavLink to="/">
              <FontAwesomeIcon icon={faStar} fontSize={18} />
              <span>Dashboard</span>
            </NavLink>

            <NavLink to="/games">
              <FontAwesomeIcon icon={faTrophy} fontSize={18} />
              <span>My Games</span>
            </NavLink>

            <NavLink to="/profile">
              <FontAwesomeIcon icon={faUser} fontSize={18} />
              <span>Profile</span>
            </NavLink>

            <button onClick={logout}>
              <FontAwesomeIcon icon={faRightFromBracket} fontSize={18} />
              <span>Log Out</span>
            </button>
          </div>
        </Container>
      </nav>
    </Fragment>
  );
};

export default Header;
