import { useCookies } from 'react-cookie';

import { Layout } from '../components';

import { FutureGames } from '../sections';

const Home = () => {
  const [cookies, setCookies] = useCookies();

  return (
    <Layout cookies={cookies}>
      <FutureGames />
    </Layout>
  );
};

export default Home;
