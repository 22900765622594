import { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';

import { ProtectRoutes } from './hooks/protectRoutes';
import { useAuth } from './hooks/useAuth';

import { Login, SignUp, Home, Games, SingleGame, Profile } from './pages';

export default function App() {
  const { cookies, logout } = useAuth();

  const validateToken = async () => {
    try {
      const response = await axios.post('https://admin.wstqst.com/wp-json/jwt-auth/v1/token/validate', null, {
        headers: {
          Authorization: `Bearer ${cookies.token}`,
        },
      });

      if (response.status === 403) logout();
    } catch (error) {
      console.log(error);
      logout();
    }
  };

  useEffect(() => {
    return () => cookies.token && validateToken();
  }, [cookies.token]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" exact />} />
      <Route path="/sign-in" element={<Login />} />
      <Route path="/sign-up" element={<SignUp />} />

      <Route element={<ProtectRoutes />}>
        <Route path="/dashboard" element={<Home />} />
        <Route path="/games" element={<Games />} />
        <Route path="/games/:id" element={<SingleGame />} />
        <Route path="/profile" element={<Profile />} />
      </Route>
    </Routes>
  );
}
