import Countdown from 'react-countdown';

import { Container } from '../../components';

import styles from './userFutureGames.module.scss';

const UserFutureGames = ({ data }) => (
  <section className={styles['my-future-games']}>
    <Container>
      <div className="hr" />
      <h2>My Future Games:</h2>

      {data.map((game) => (
        <div className={styles['game-card']} key={game.id}>
          <img src={game.acf.game_info.wallpaper} alt={game.acf.game_info.title} />

          <div>
            <div>
              Game starts in: <Countdown date={game.acf.game_info.date} />
            </div>
          </div>
        </div>
      ))}
    </Container>
  </section>
);

export default UserFutureGames;
