import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import Slider from 'react-slick';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin } from '@fortawesome/free-solid-svg-icons';

import gameServices from '../../services/games.services';

import styles from './future-games.module.scss';

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const FutureGames = () => {
  const [games, setGames] = useState();

  const getGamesList = async () => {
    const response = await gameServices.getAllGames();

    if (response.status === 200) {
      const featuredGames = response.data.filter(
        (el) =>
          (moment(el.acf.game_info.date) >= moment().endOf('day') || !el.acf.game_info.date) &&
          (!el.acf.status.is_running || el.acf.status.is_on_hold),
      );

      setGames(featuredGames);
    }
    try {
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please try again!');
      console.log(error);
    }
  };

  useEffect(() => {
    getGamesList();
  }, []);

  return (
    <section className={styles['future-games']}>
      <h2 className="section-title">Future Games</h2>

      <Slider {...sliderSettings} className={styles['game-list']}>
        {games?.map((el) => (
          <NavLink key={el.id} className={styles['game-card']} to={{ pathname: `/games/${el.id}` }}>
            <div>
              <img src={el.acf.game_info.wallpaper} alt={el.acf.game_info.title} />
            </div>

            <div className={styles['game-card__info']}>
              <h4>{el.acf.game_info.title}</h4>
              <p>{el.acf.game_info.date}</p>
              <p>
                <FontAwesomeIcon icon={faMapPin} fontSize={16} />

                <span>{el.acf.game_info.location}</span>
              </p>
            </div>
          </NavLink>
        ))}
      </Slider>
    </section>
  );
};

export default FutureGames;
