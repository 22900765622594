import Container from '../Container/Container';

import styles from './footer.module.scss';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <Container>
        <span>Copyright © {currentYear} West Quest - All Right Reserved</span>

        <a href="https://www.linkedin.com/in/daniil-alekseev/" target="_blank" rel="noReferrer">
          Designed by <span>D. Aleksieiev</span>
        </a>
      </Container>
    </footer>
  );
};

export default Footer;
