import { NavLink } from 'react-router-dom';

import styles from './registration.module.scss';

const SingUpForm = ({ submit }) => (
  <div className={styles.registration}>
    <h1>West Quest</h1>

    <p>Create an account to start the quest</p>

    <form onSubmit={submit}>
      <input name="username" type="text" placeholder="Username" required />
      <input name="firstName" type="text" placeholder="First Name" required />
      <input name="lastName" type="text" placeholder="Last Name" required />
      <input name="email" type="email" placeholder="Email" required />
      <input name="password" type="password" placeholder="Password" required />
      <input name="password_confirm" type="password" placeholder="Confirm Password" required />

      <button className="btn submit">Sign Up</button>
    </form>

    <NavLink to="/sign-in">Already have an account? Sign In</NavLink>
  </div>
);

export default SingUpForm;
