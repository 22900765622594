import { Fragment, useEffect, useState } from 'react';
import { Container, Spinner } from '../../components';
import Confetti from 'react-confetti';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import styles from './tasks.module.scss';

const Tasks = ({ tasks, userProgress, submit, confetti }) => {
  const [list, setList] = useState();

  useEffect(() => {
    const result = {};

    tasks.forEach((task) => {
      result[task.level_name] = {
        level_task: task.level_task,
      };
    });

    userProgress.forEach((prog) => {
      if (result[prog.level_name]) {
        result[prog.level_name].status = prog.status;
      }
    });

    setList(
      Object.keys(result).map((level_name) => ({
        level_name,
        ...result[level_name],
      })),
    );
  }, [userProgress]);

  if (!list) return <Spinner />;

  return (
    <section className={styles.tasks}>
      <Container>
        {/* TODO: PROGRESS BAR с делениями по сделанным задачам */}

        {confetti && <Confetti width={500} height={1000} />}

        {list.map((el, index) => (
          <Fragment key={index}>
            <div className={styles['task-card']}>
              <p className={styles['task-card__title']}>
                <span>{el.level_name}</span>

                {index !== 0 && list[index > 0 ? index - 1 : 0].status !== true && (
                  <div>
                    <FontAwesomeIcon icon={faLock} fontSize={18} />
                  </div>
                )}

                {el.status && (
                  <div>
                    <FontAwesomeIcon icon={faCircleCheck} fontSize={18} />
                  </div>
                )}
              </p>

              {(index === 0 || list[index - 1].status === true) && (
                <Fragment>
                  <p className={styles['task-card__description']}>{el.level_task}</p>

                  {!userProgress.find((task) => task.level_name === el.level_name).status && (
                    <form onSubmit={(event) => submit(event, el.level_name)}>
                      <input type="text" name="answer" placeholder="Answer" required />

                      <button
                        className="btn submit"
                        disabled={userProgress.find((task) => task.level_name === el.level_name).status}
                      >
                        Submit
                      </button>
                    </form>
                  )}
                </Fragment>
              )}
            </div>
            <div className="hr" />
          </Fragment>
        ))}
      </Container>
    </section>
  );
};

export default Tasks;
