import { NavLink } from 'react-router-dom';

import { Container } from '../../components';

import styles from './currentGame.module.scss';

const CurrentGame = ({ data, progress }) => (
  <section className={styles['current-game']}>
    <Container>
      <h2>You’re in for:</h2>

      <NavLink to={`/games/${data.id}`} className={styles['game-link']}>
        <img src={data.acf.game_info.wallpaper} alt={data.acf.game_info.title} />

        <div>
          <p>
            Progress: <span>{progress}</span>
          </p>

          <div>Press To Continue</div>
        </div>
      </NavLink>
    </Container>
  </section>
);

export default CurrentGame;
