import api from './api';

const register = (user) => {
  const response = api.post('wp/v2/users', user);

  return response;
};

const getProfile = (id) => {
  const response = api.get(`wp/v2/profiles?slug=${id}`);

  return response;
};

const updateProfile = (id, data, token) => {
  const response = api.patch(`wp/v2/profiles/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response;
};

const getUserById = (id) => {
  const response = api.get(`wp/v2/users/${id}`);

  return response;
};

const userServices = {
  register,
  getProfile,
  getUserById,
  updateProfile,
};

export default userServices;
