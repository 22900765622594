import { InputMask } from '@react-input/mask';
import { Fragment } from 'react';

const UserInfoForm = ({ submit, data }) => (
  <div>
    <form onSubmit={submit}>
      <div>Personal Info:</div>

      <InputMask
        mask="(___) ___-__-__"
        replacement={{ _: /\d/ }}
        placeholder="Phone Number"
        name="phone"
        required
        defaultValue={data.personal.phone_number}
      />

      <InputMask
        mask="__/__/____"
        replacement={{ _: /\d/ }}
        placeholder="Birthday (MM/DD/YYYY)"
        name="bd"
        required
        defaultValue={data?.personal.birthday}
      />

      <input name="fb" type="text" placeholder="Facebook Link" defaultValue={data.personal.facebook_link || ''} />

      <div>Car Info:</div>

      <Fragment>
        <input name="car_make" type="text" placeholder="Make" required defaultValue={data.car.make} />
        <input name="car_model" type="text" placeholder="Model" required defaultValue={data.car.model} />
        <input name="car_plate" type="text" placeholder="Plate" required defaultValue={data.car.plate} />
      </Fragment>

      <button className="btn submit">Save</button>
    </form>
  </div>
);

export default UserInfoForm;
