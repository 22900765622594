import { NavLink } from 'react-router-dom';

import styles from './login.module.scss';

const LoginForm = ({ submit }) => (
  <div className={styles.login}>
    <h1>West Quest</h1>

    <p>Login to your account</p>

    <form onSubmit={submit}>
      <input name="username" type="text" placeholder="Username" required />
      <input name="password" type="password" placeholder="Password" required />

      <button className="btn submit">Sign In</button>
    </form>

    <NavLink to="/sign-up">Don't have an account? Sign Up</NavLink>
  </div>
);

export default LoginForm;
