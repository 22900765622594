import { createContext, useContext, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import api from '../services/api';

import userServices from '../services/user.services';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookie] = useCookies();

  const login = async ({ username, password }) => {
    const response = await api.post('/jwt-auth/v1/token', {
      username: username,
      password: password,
    });

    const user_profile = await userServices.getProfile(response.data.user_id);

    setCookies('token', response.data.token);
    setCookies('name', response.data.user_display_name);
    setCookies('id', response.data.user_id);
    setCookies('email', response.data.user_email);
    setCookies('profile_id', user_profile.data[0].id);

    if (cookies.username) removeCookie('username');

    navigate('/dashboard');
  };

  const logout = () => {
    ['token', 'name', 'username', 'id', 'email'].forEach((obj) => removeCookie(obj));
    navigate('/sign-in');
  };

  const value = useMemo(
    () => ({
      cookies,
      login,
      logout,
    }),
    [cookies],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useAuth = () => {
  return useContext(UserContext);
};
