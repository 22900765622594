import styles from './mobile.module.scss';

const MobileOnly = () => (
  <section className={styles.message}>
    <h1>
      This application was created for use on a mobile phone (iOS or Android).
      <br />
      To use the application, follow this link from your mobile device.
    </h1>

    <a href="https://wstqst.com" rel="noReferrer">
      www.wstqst.com
    </a>
  </section>
);

export default MobileOnly;
