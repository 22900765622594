import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import moment from 'moment';

import { Layout, Spinner } from '../components';

import { CurrentGame, UserFutureGames } from '../sections';

import userServices from '../services/user.services';
import gameServices from '../services/games.services';

const Games = () => {
  const [userGames, setUserGames] = useState();
  const [currentGame, setCurrentGame] = useState();
  const [previousGames, setPreviousGames] = useState([]);
  const [futureGames, setFutureGames] = useState([]);

  const [cookies] = useCookies();

  const getProfileData = async () => {
    try {
      const response = await userServices.getProfile(cookies.id);

      if (response.status === 200) setUserGames(response.data[0].acf.games);
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please reload the page!');
      console.log(error);
    }
  };

  const getGamesInfo = async (id) => {
    try {
      const response = await gameServices.getGameById(id);

      if (response.status === 200) {
        if (response.data.acf.status.is_running) setCurrentGame(response.data);

        if (moment(response.data.acf.game_info.date) < moment().endOf('day'))
          setPreviousGames((prevState) => [...prevState, response.data]);

        if (moment(response.data.acf.game_info.date) >= moment().endOf('day') && !response.data.acf.status.is_running)
          setFutureGames((prevState) => [...prevState, response.data]);
      }
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please reload the page!');
      console.log(error);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    if (userGames) userGames.forEach((_, index) => getGamesInfo(userGames[index].game_id));
  }, [userGames]);

  return (
    <Layout cookies={cookies}>
      {/* TODO: Previous Games list without links (only result and score) */}

      {currentGame && (
        <CurrentGame
          data={currentGame}
          progress={
            userGames.find((el) => el.game_id === currentGame.id) &&
            (userGames.find((el) => el.game_id === currentGame.id).progress || []).find((v) => !v.status)?.level_name
          }
        />
      )}

      {futureGames ? <UserFutureGames data={futureGames} /> : <Spinner />}
    </Layout>
  );
};

export default Games;
